import React from 'react'
import { BsSearch } from "react-icons/bs";

function HomeOne() {
  return (
    <div className='HomeOne'>
        <div className="title">
            Какая услуга вас <br /> интересует?
        </div>

        <div className="searchInput">
            <div className="icon">
                <BsSearch />
            </div>
            <input type="text" placeholder='Поиск...' />
        </div>
    </div>
  )
}

export default HomeOne