import React, { useEffect } from 'react';
import HomeOne from '../components/Home/HomeOne';
import CategoryBlock from '../components/Home/CategoryBlock';
import useCategoryStore from '../store/categoriesStore';

function Home() {
  const categories = useCategoryStore((state) => state.categories);
  const fetchCategories = useCategoryStore((state) => state.fetchCategories);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <div className='Home'>
      <HomeOne />

      <div className="home__container">
        {categories.map((category) => (
          <CategoryBlock
            key={category._id}
            id={category._id}
            title={category.name}
            text={category.description}
            cards={category.subcategories.slice(0, 3).map((subcategory) => ({
              title: subcategory.name,
              text: `${subcategory.offers || 0} предложений`,
              image: subcategory.imageUrl,
            }))}
          />
        ))}
      </div>
    </div>
  );
}

export default Home;
