import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { api } from '../api/api'; // Импортируем существующий API
import { showNotification } from '../ToastNotification';
import WebApp from '@twa-dev/sdk'; // Импортируем SDK для Telegram WebApp

const useOrderStore = create(
  devtools((set) => ({
    orders: [], // Храним заказы
    loading: false,

    // Метод для создания заказа
    createOrder: async (orderData, files) => {
      set({ loading: true });
      try {
        const telegramId = WebApp.initDataUnsafe?.user.id;
        if (!telegramId) throw new Error('Не удалось получить telegramId пользователя.');

        const formData = new FormData();
        formData.append('title', orderData.title);
        formData.append('description', orderData.description);
        formData.append('acceptablePrice', orderData.acceptablePrice);
        formData.append('paymentMethod', orderData.paymentMethod);
        formData.append('telegramId', telegramId);
        formData.append('categoryId', orderData.categoryId);
        formData.append('subcategoryId', orderData.subcategoryId);

        files.forEach((file) => {
          formData.append('files', file.file);
        });

        const response = await api.post('/orders', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        set((state) => ({ orders: [...state.orders, response.data], loading: false }));
        showNotification('Заказ успешно создан!', 'success');
      } catch (error) {
        console.error('Ошибка при создании заказа:', error);
        showNotification('Ошибка при создании заказа', 'error');
        set({ loading: false });
      }
    },

    // Метод для получения заказов текущего пользователя
    fetchUserOrders: async () => {
      set({ loading: true });
      try {
        const telegramId = WebApp.initDataUnsafe?.user.id;
        if (!telegramId) throw new Error('Не удалось получить telegramId пользователя.');

        const response = await api.get(`/orders?telegramId=${telegramId}`);
        set({ orders: response.data, loading: false });
      } catch (error) {
        console.error('Ошибка при получении заказов:', error);
        showNotification('Ошибка при загрузке заказов', 'error');
        set({ loading: false });
      }
    },
  }))
);

export default useOrderStore;
