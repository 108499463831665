import React from 'react';
import CategoryCard from './CategoryCard';
import Button from '../ui-kit/Button';

function CategoryBlock(props) {
  return (
    <div className='CategoryBlock'>
      <div className="title">
        {props.title}
      </div>

      <div className="text">
        {props.text}
      </div>

      <div className="categoryGrids">
        {props.cards.map((card, index) => (
          <CategoryCard key={index} title={card.title} text={card.text} image={card.image} />
        ))}
      </div>

      <Button to={`/category/${props.id}`} text={`Все в данной категории`} />
    </div>
  );
}

export default React.memo(CategoryBlock);
